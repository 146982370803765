* {
  font-family: "Poppins";
  font-weight: 400;
}
body {
  margin: 0;
  letter-spacing: 0.4px;
}
@media (min-width: 1410px) {
  .container {
    max-width: 1380px;
  }
}

.button {
  background-color: #9b0000;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  transition: all 0.5s ease;
  border-radius: 0px;
  padding: 10px 20px;
}
.button a,
.button {
  text-decoration: none;
  color: #fff;
}
.button:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}
.button::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 0px;
  background: #9b0000;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.button:hover {
  background-color: #000;
  color: #fff;
}
/* banner effect */
.ba_beffect {
  display: block;
  overflow: hidden;
  position: relative;
}
.ba_beffect img {
  max-width: 100%;
  width: 100%;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.ba_beffect::before {
  transform: scale(0, 1);
  -webkit-transform: scale(0, 1);
  -moz-transform: scale(0, 1);
  -o-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
}
.ba_beffect::after {
  transform: scale(1, 0);
  -webkit-transform: scale(1, 0);
  -moz-transform: scale(1, 0);
  -o-transform: scale(1, 0);
  -ms-transform: scale(1, 0);
}
.ba_beffect::before,
.ba_beffect::after {
  content: "";
  border-radius: 0px;
  bottom: 10px;
  left: 10px;
  opacity: 0;
  position: absolute;
  right: 10px;
  top: 10px;
  background: rgba(0, 0, 0, 0.1) none repeat scroll 0 0;
  transition: opacity 0.35s ease 0s, transform 0.5s ease 0s;
  -webkit-transition: opacity 0.35s ease 0s, transform 0.5s ease 0s;
  -moz-transition: opacity 0.35s ease 0s, transform 0.5s ease 0s;
  -o-transition: opacity 0.35s ease 0s, transform 0.5s ease 0s;
  -ms-transition: opacity 0.35s ease 0s, transform 0.5s ease 0s;
}
.ba_beffect:hover::before,
.ba_beffect:hover::after {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
}
/* ______________________________Header______________________________ */

.header {
  background-color: transparent;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}
.navbar-light .navbar-brand {
  margin: 0;
}
.navbar-expand-lg {
  display: block;
}
.header .navbar-expand-lg .navbar-collapse {
  flex-grow: unset;
  flex-basis: unset;
}

.inner-header {
  display: flex;
  margin: 0 auto;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.navbar-light .navbar-nav .nav-link a,
.navbar-light .navbar-nav .nav-link {
  color: #ffffff;
  text-decoration: none;
  font-size: 18px;
  position: relative;
  transition: 0.4s ease;
  margin: 0px 10px;
}
.navbar-light .navbar-nav .nav-link.active a {
  color: #9b0000;
  font-weight: 600;
}

.header .fas {
  color: #ffffff !important;
}
.navbar-light .navbar-nav .nav-link:focus {
  color: #ffffff;
}
.dropdown-item a {
  color: #000000;
  text-decoration: none;
}
.dropdown-item:hover a {
  color: #9b0000;
}
.dropdown-item:active {
  background-color: #e9ecef60;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: #ffffff;
}
.dropdown-menu {
  border-radius: 0px !important;
}
.navbar-light .navbar-nav .nav-link a:hover,
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--mainColor) !important;
}
.navbar-light .navbar-nav .nav-link a:after {
  background: none repeat scroll 0 0 transparent;
  bottom: -5px;
  content: "";
  display: block;
  height: 1px;
  left: 50%;
  position: absolute;
  background: #9b0000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.navbar-light .navbar-nav .nav-link a:hover:after {
  width: 100%;
  left: 0;
}
.header .fas {
  color: #000;
  margin-left: -10px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: transparent;
}
.dropdown-item a {
  color: #000000;
  text-decoration: none;
}
/* toggle */
.navbar-toggler-icon:after {
  content: "\f0c9";
  font-family: "fontAwesome";
  color: white;
}

.navbar-light .navbar-toggler {
  border: none;
  padding: 0px;
}
.w3-overlay {
  display: none;
}
.navbar-toggle {
  display: none;
}
.header button {
  color: #fff;
  background-color: transparent;
  border: none;
}

.btn-navbar:focus-visible {
  outline: none;
}
.btn-navbar .btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
  background-color: transparent;
  color: #fff;
}
.header button:focus {
  background-color: transparent;
  color: #000;
  box-shadow: none;
}
.hidden-lg {
  display: none;
}
/* ______________________________________scroll-button______________________________________ */
.styles_scroll-to-top__2A70v {
  background-color: var(--mainColor) !important;
  transition: 0.5s ease !important;
  border-radius: 0px !important;
}
.styles_scroll-to-top__2A70v svg {
  width: 20px;
  transition: 0.5s ease;
}
.styles_scroll-to-top__2A70v:hover {
  background-color: #000 !important;
}
.styles_scroll-to-top__2A70v:hover svg {
  fill: #fff;
}
/* ___________________________Footer_____________________________ */
.footer {
  background-color: #000000;
  padding: 55px 0px;
}
.Footerbox img {
  margin-bottom: 30px;
}
.footer-text {
  color: #fff;
  line-height: 35px;
}
.footer-text p {
  font-size: 14px;
  line-height: 35px;
  max-width: 350px;
  color: #b7b1b1;
}
.footer-text .list-unstyled a {
  font-size: 14px;
  color: #b7b1b1;
  text-decoration: none;
  transition: 0.4s ease;
}
.footer-text .list-unstyled a:hover {
  color: var(--mainColor);
}
.footer-text h3 {
  font-size: 22px;
  color: #ffffff;
  margin-bottom: 30px;
}
.social-icon li {
  width: 40px;
  background-color: #ffffff;
  text-align: center;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: 0.4s ease;
  margin-right: 10px;
}
.social-icon li:hover {
  background-color: var(--mainColor);;
}
.social-icon i {
  font-size: 18px;
  color: black;
  transition: 0.4s ease;
  padding: 10px 0px;
}
.social-icon li:hover i {
  color: #fff;
}

.footer-bottom {
  background-color: var(--mainColor);
  padding: 15px;
  margin: 0;
  text-align: center;
  color: #ffffff;
}
@media (max-width: 991px) {
  .container {
    max-width: 100%;
  }

  /* __________________________Navbar____________________________________ */
  .sidebar.active {
    left: 0;
  }
  .btn-navbar {
    padding-left: 0px;
  }
  .sidebar {
    background-color: #fff;
    box-shadow: 0 4px 8px rgb(0 0 0 / 16%);
    left: -100%;
    min-height: 100vh;
    position: fixed;
    top: 0;
    transition: 0.5s;
    width: 280px;
    z-index: 1;
  }
  .closebtn.btn-primary {
    background-color: transparent;
    border: none;
    color: #fff;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    color: #000 !important;
  }
  .navbar-nav {
    margin: 0 15px;
  }

  .navbar {
    margin: 0px 0px;
  }
  .navbar-brand {
    order: 2;
  }
  .button {
    order: 3;
  }
  .header .ourSurvice {
    order: 3;
  }
  .navbar-nav .dropdown-menu {
    padding: 0px;
  }

  .header .navbar-expand-lg .navbar-collapse,
  .dropdown-menu {
    position: absolute;
    top: 50px;
    background-color: #fff;
    width: 100%;
    left: 0;
    right: 0;
    padding: 15px;
    border-radius: 0px !important;
    border: 1px solid #fff;
  }
  .dropdown-toggle {
    position: relative;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    margin: 0 !important;
  }
  .navbar-toggler:focus {
    box-shadow: none;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: none;
    height: 100%;
    width: 100%;
    padding-top: 12px;
    font-size: 25px;
  }
  .aboutSlider .slick-prev {
    left: 30px !important;
  }
  .aboutSlider .slick-next {
    left: 80px !important;
  }
  .navbar-toggle {
    display: block;
  }
  .w3-overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 3;
  }

  #stamenu .menu-vertical {
    overflow-y: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    z-index: 4;
    background: #fff;
    width: 280px;
    transform: translateX(-100%);
    transition: all 0.5s ease-out 0s;
  }
  #stamenu.active .menu-vertical {
    transform: translateX(0);
  }
  .sidenav .close-nav {
    align-items: center;
    background-color: #1e1e1e;
    color: #fff;
    display: flex;
    justify-content: space-between;
    letter-spacing: 1px;
    padding: 12px 15px;
    width: 100%;
  }
  .sidenav .menu-responive {
    padding: 0 12px;
  }
  .close-nav a {
    color: #ffffff;
  }
}
@media (min-width: 768px) {
  .FooterVertical .btn {
    display: none;
  }
  .FooterVertical .collapse {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .footerLinkHeading {
    position: relative;
  }
  .FooterVertical .btn {
    text-shadow: none;
    background: none;
    background-image: none;
    background-image: none;
    border: 0;
    box-shadow: none;
    font-size: 12px;
    position: absolute;
    top: -3px;
    right: 0;
    padding: 0;
    width: 100%;
  }
  .FooterVertical .btn::after {
    font-family: "FontAwesome";
    content: "\f067";
    font-size: 14px;
    font-weight: normal;
    float: right;
    cursor: pointer;
  }
  .FooterVertical .btn[aria-expanded="true"]::after {
    font-family: "FontAwesome";
    content: "\f068";
    font-size: 14px;
    font-weight: normal;
    float: right;
    cursor: pointer;
  }
  .btn-primary:active:focus {
    box-shadow: none;
  }

  .footer-text p {
    font-size: 14px;
    line-height: 20px;
    max-width: 100%;
  }
  .footer-text h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .modal {
    top: -50px;
  }
  .modal {
    top: -50px;
  }
}
/* ********************************************Responsive************************************************ */
/* ***************************************************** 1200 - 1410 ***************************************************** */

@media (min-width: 1200px) and (max-width: 1410px) {
  /* _____________________________ Navbar _____________________________ */
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    font-size: 16px;
    margin: 0px 4px;
  }
 

}
/* ________________ Footer ______________ */
.contactUsIconContain span {
  max-width: 228px;
}

/* ***************************************************** 992 - 1199 ***************************************************** */

@media (min-width: 992px) and (max-width: 1199px) {
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    margin: 0px 5px;
  }
  .header .button {
    font-size: 14px;
    margin: 3px 0;
    padding: 0 10px;
  }

  /* _____________________________ Sign up _____________________________ */

  .form-container {
    width: 900px;
    height: 500px;
  }
  /* _____________________________ Footer _____________________________ */
  .footer-text p {
    font-size: 14px;
    line-height: 28px;
  }
  .Footerbox img {
    margin-bottom: 25px;
  }
  .footer-text h3 {
    margin-bottom: 25px;
    font-size: 20px;
  }
  .contactUsIconContain span {
    max-width: 183px;
    font-size: 14px;
    line-height: 28px;
  }
}
/* ***************************************************** 768 - 991 ***************************************************** */

@media (min-width: 768px) and (max-width: 991px) {
  /* ________________Banner______________ */
  .header .button {
    margin: 4px 0;
  }
  /* _____________________________navbar_____________________________ */
  .header .ourSurvice {
    max-height: 40px;
    margin-top: 5px;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link {
    font-size: 15px;
    margin: 0px 10px;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link,
  .dropdown-item a {
    font-size: 16px;
  }
  .header .navbar-expand-lg .navbar-collapse,
  .dropdown-menu {
    top: 64px;
  }
  /* ________________ Footer ______________ */
  .footer-text p {
    line-height: 30px;
  }
  .footer-text {
    line-height: 30px;
  }
  .footer-text h3 {
    font-size: 17px;
    margin-bottom: 25px;
  }
  .Footerbox img {
    margin-bottom: 10px;
  }
  .contactUsIconContain span {
    max-width: 199px;
    font-size: 14px;
    line-height: 25px;
  }
  .footer-bottom {
    padding: 12px;
  }
}
/* ***************************************************** 576 - 767 ***************************************************** */

@media (min-width: 576px) and (max-width: 767px) {
  .header .button {
    margin: 4px 0;
  }
  .Footerbox img {
    margin-bottom: 15px;
  }
  .footer {
    padding: 35px 0px;
  }
  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link,
  .dropdown-item a {
    font-size: 16px;
  }
  .header .navbar-expand-lg .navbar-collapse,
  .dropdown-menu {
    top: 64px;
  }
  /* ________________ Footer ______________ */
  .social-icon {
    margin: 30px 0 0 0;
  }
  .footer-bottom {
    padding: 10px;
  }
}
/* ***************************************************** 320 - 575 ***************************************************** */

@media (min-width: 320px) and (max-width: 575px) {
  .toogleMenu {
    font-size: 2rem;
  }
  .navbar-light .navbar-toggler {
    padding: 0;
  }
  .header .navbar-expand-lg .navbar-collapse,
  .dropdown-menu {
    top: 100%;
  }

  .navbar-light .navbar-nav .nav-link a,
  .navbar-light .navbar-nav .nav-link,
  .dropdown-item a {
    font-size: 14px;
  }
  .footer {
    padding: 20px 0px;
  }
  .social-icon {
    margin: 0px;
  }
  .footer-text p {
    margin-top: 10px;
  }
  .F-copywrite {
    font-size: 12px;
  }
  .scroll-icon {
    padding: 5px 7px;
  }
  .scroll-icon i {
    font-size: 15px;
    margin: 0px 4px;
  }
  /* ____________Footer____________ */
  .Footerbox img {
    margin-bottom: 0px;
  }
  .social-icon li {
    width: 35px;
    height: 35px;
    padding: 0;
  }
  .social-icon i {
    font-size: 16px;
  }
  .footer-text {
    line-height: 26px;
  }
  .footer-text h3 {
    margin-bottom: 10px;
    font-size: 15px;
  }
  .footer-text p {
    font-size: 13px;
  }
  .contactUsIconContain span {
    max-width: 263px;
  }
  .footer-bottom {
    padding: 10px;
  }
  .quickContact {
    line-height: 25px;
  }
}
