.main {
  width: 100%;
  height: 100vh;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.4);
  /* background-color: #000000cc; */
}
.content .titleName {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 3rem;
}
.content .titleNameSub {
  font-weight: 400;
  text-transform: capitalize;
  font-size: 2rem;
}
.content .contenuDesc {
  color: rgb(141, 137, 138);
}
.voirPlus {
  border-radius: 20px;
  padding-inline: 35px;
  background-color: var(--mainColor);
  border-color: var(--mainColor);
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}