.part1,
.part2,
.part3
{
    padding: 80px 10px;
    color: white;
    background-attachment: fixed;
}
.part1{
    background-image: url('../../assets/O1.jpg');
}
.part2{
    background-image: url('../../assets/02.jpg');
}
.part3{
    background-image: url('../../assets/03.jpg');
    padding: 100px 10px;
}