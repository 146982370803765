.presentation {
    /* background-color: rgb(231, 231, 231); */
    padding-top: 75px;
    padding-bottom: 75px;
    /* min-height: 850px; */
    /* background-image: url('../../assets/Images/SalahEddine.jpeg'); */
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: right;
}
.bg-background-cover{
    background-color: var(--mainColor);
    width: 400px;
    height: 400px;
    /* border-radius: 50%; */
    margin-left: auto;
}
.imgSalah{
    position: absolute;
    top: 10%;
    left: 20%;
    border-radius: 50%;
}

.text-mainColor {
    color: var(--mainColor);
}