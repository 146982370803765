.suitCase {
    background-color: var(--mainColor);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.experiences .desc {
    margin-left: 60px;
}
.mission {
    font-weight: 600;
    font-size: 20px;
    letter-spacing: -1px;

}
