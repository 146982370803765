.ourBlog {
    margin: 80px 0px 60px 0px;
  }
  .ourBlogCard {
    margin-top: 40px;
  }
  .ourBlogCard .card-body {
    padding: 30px;
  }
  .ourBlogContain .ourBlogContainIcon {
    float: right;
  }
  .ourBlogContainIcon i {
    color: var(--mainColor);
    margin-right: 5px;
  }
  .ourBlogCard .card-title {
    font-size: 20px;
    line-height: 35px;
    color: #000000;
    transition: 0.4s ease;
    cursor: pointer;
  }
  .ourBlogCard .card-title:hover {
    color: var(--mainColor);
  }
  .ourBlogCard .card-text {
    font-size: 14px;
    line-height: 30px;
    color: #666666;
  }
  .ourBlogCard .card {
    border-radius: 10px;
  }
  .slick-slider [class*="col-"] {
    padding: 0 15px;
  }
  .ourBlogCard .slick-slide img {
    display: block;
    border-radius: 10px 10px 0px 0px;
  }
  
  .ourBlog .slick-next {
    display: block;
    bottom: 0;
    top: -50px;
    right: 20px;
    left: auto;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 5px !important;
    background-color: var(--mainColor);
    opacity: unset;
    transition: 0.5s ease;
    box-shadow: 0 0 10px #e2e1e1;
  }
  .ourBlog .slick-prev {
    display: block;
    bottom: 0;
    top: -50px;
    right: 70px;
    left: auto;
    z-index: 1;
    width: 40px;
    height: 40px;
    border-radius: 5px !important;
    background-color: var(--mainColor);
    opacity: unset;
    transition: 0.5s ease;
    box-shadow: 0 0 10px #e2e1e1;
  }
  .ourBlog .slick-next:hover,
  .ourBlog .slick-prev:hover {
    background-color: #ffffff;
  }
  .ourBlog .slick-next::before {
    content: "\f061";
    font-family: FontAwesome;
    font-size: 25px;
    opacity: 1;
    transition: 0.5s ease;
  }
  .ourBlog .slick-prev::before {
    content: "\f060";
    font-family: FontAwesome;
    font-size: 25px;
    opacity: 1;
    transition: 0.5s ease;
  }
  .ourBlog .slick-arrow:hover::before {
    color: black;
  }
  