*{
    font-family: 'Poppins', sans-serif;
}
:root {
    --mainColor : rgb(59, 185, 181);
}
.text-justify {
    text-align: justify;
}

.title {
    font-weight: 600;
}
.title::after {
    content: '';
    display: block;
    margin-top: 10px;
    height: 5px;
    width: 100px;
    border-radius: 10px;
    border-left: 80px solid var(--mainColor);
    border-right: 10px solid var(--mainColor);
}
.mainDate::after {
    content: '';
    display: block;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 5px;
    width: 30px;
    border-radius: 10px;
    border-left: 25px solid var(--mainColor);
}
.titleSkills::after {
    margin-inline: auto;
}
.btn-main {
    background-color: var(--mainColor);
    border-color: var(--mainColor);
    padding:10px 20px;
}
.btn-main svg {
    height: 0.8em !important;
}
.text-color-primary {
    color: var(--mainColor);
    font-size: 1.3rem;
    font-weight: 600;
}
.skllsLogos {
    margin-top: -100px;
    margin-bottom: -150px;
}

.referncesimage {
    transition: all 0.2s;
    filter: grayscale(100%);
}
.referncesimage:hover{
    filter: grayscale(0%);
}
.skillsimage {
    width: 50%;
    margin-inline: auto;
}
.text-start{
    text-align: start;
}
.btn-contact{
    background-color: var(--mainColor);
    color: white;
    width: 100%;
    border-radius: 10px;
    border: 1px solid var(--mainColor);
}
.btn-contact:hover{
    background-color: white;
    color: var(--mainColor);
}
.contactez,
.contact{
    background-color: #1a1a1a;
    color: white;
}