.mainBannerDiv {
    height: 100vh;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    position: relative;
}
.mainBannerDiv .titleName {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 3rem;
}
.mainBannerDiv .titleNameSub {
    font-weight: 400;
    text-transform: capitalize;
    font-size: 2rem;
}
.mainBannerDiv .contenuDesc {
    color: rgb(141, 137, 138);
}
.voirPlus {
    border-radius: 20px;
    padding-inline: 35px;
    background-color: var(--mainColor);
    border-color: var(--mainColor);
}
#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
}